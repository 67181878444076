import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, SegmentedGroup, Segmented, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceGrid.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faGridDividers, faBorderNone, faGrid2Plus, faPipe, faFileCsv} from "@fortawesome/pro-duotone-svg-icons";
import 'react-data-grid/lib/styles.css';
import DataGrid, {textEditor} from 'react-data-grid';
import Empty from "../utils/Empty";
import dayjs from "dayjs";
import DashboardGridAdd from "../dialogs/DashboardGridAdd";
import DashboardGridMove from "../dialogs/DashboardGridMove";
import DashboardGridColumnNew from "../dialogs/DashboardGridColumnNew";
import DashboardGridRename from "../dialogs/DashboardGridRename";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceGrid({locale, width, setToast, Dialog, workspaceItem, dialog, loading, setLoading}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [update, setUpdate] = useState({});
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [menu, setMenu] = useState('original');

    const ExportCSV = (itemColumns, itemRows) => {
        const headerRow = itemColumns.map(column => column.name).join(',');

        const dataRows = itemRows.map(row => {
            return itemColumns
                .map(column => row[column.key] || '')
                .join(',');
        }).join('\n');

        return headerRow + '\n' + dataRows;
    }

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const ButtonGridDownload = () => {
        let itemColumns = columns;
        let itemRows = rows;
        const itemDate = dayjs().valueOf().toString();

        itemColumns = itemColumns.slice(1);

        itemColumns = itemColumns.map(column => {
            const {key, name} = column;
            return {key, name};
        });

        itemRows = itemRows.map(row => {
            const {id, ...rest} = row;
            return rest;
        });

        const csvContent = ExportCSV(itemColumns, itemRows);

        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        downloadLink.download = itemDate + '.csv';
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const ButtonGridDelete = () => {
        confirm({
            title: locale.dashboardWorkspaceGrid.j,
            message: locale.dashboardWorkspaceGrid.k,
            okText: locale.dashboardWorkspaceGrid.ab,
            cancelText: locale.dashboardWorkspaceGrid.l,
            callback: (res) => {
                if (res) {
                    setLoading(true);

                    const itemUpdate = update;
                    itemUpdate.item = workspaceItem.id;

                    const message = {
                        type: 'wss',
                        path: 'grid-delete',
                        action: 'update',
                        data: itemUpdate
                    };
                    wsCall(message);
                }
            }
        });
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    const DatagridRowsSelect = data => {
        const item = {};
        if (data.column.key === 'id' && data.row.id) {
            item.type = 'row';
            item.key = data.row.id;
        }
        setUpdate(item);
    }

    const DatagridColumnsReorder = (sourceKey, targetKey) => {

        const message = {
            type: 'wss',
            path: 'grid-reorder',
            action: 'update',
            data: {
                source:  sourceKey,
                target: targetKey,
                item: workspaceItem.id,
                type: 'column'
            }
        };
        wsCall(message);
    }

    const DatagridColumnsChange = data => {
        const item = {
            type: 'column',
            key: data[0].columnKey
        }
        setUpdate(item);
    }

    const DatagridColumnsResize = (idx, widthx) => {
        const array = [...columns];
        array[idx].width = widthx;
        setColumns(array);
    }

    const DatagridSelectReset = data => {
        if (data.column && data.column.key === 'id' && !data.row) {
            setUpdate({});
        }
    }

    const DatagridColumnsWidth = () => {
        const array = columns;
        return array.slice(1).some(column => 'width' in column);
    }

    const DatagridRowsChange = data => {
        setRows(data);

        const message = {
            type: 'wss',
            path: 'grid-rows',
            action: 'update',
            data: {
                rows:  data,
                columns: DatagridColumnsWidth() ? columns : null,
                item: workspaceItem.id
            }
        };
        wsCall(message);
    }

    const UpdateGridReorder = useCallback(data => {
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
    }, []);

    const UpdateGridAdd = useCallback(data => {
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
        if (data.rows) {
            setRows(data.rows);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (loading) {
            setLoading(false);
        }
    }, [loading, dialog.view, Dialog]);

    const UpdateGridDelete = useCallback(data => {
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
        if (data.rows) {
            setRows(data.rows);
        }
        if (loading) {
            setLoading(false);
        }
    }, [loading]);

    const UpdateGridRows = useCallback(data => {
        if (data.rows !== rows) {
            setRows(data.rows);
        }
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
    }, [rows]);

    const UpdateGridMove = useCallback(data => {
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
        if (data.rows) {
            setRows(data.rows);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (loading) {
            setLoading(false);
        }
    }, [Dialog, dialog.view, loading]);

    const UpdateGridColumnName = useCallback(data => {
        if (data.columns) {
            if (data.columns.length !== 0) {
                data.columns = data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );
            }
            setColumns(data.columns);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (loading) {
            setLoading(false);
        }
    }, [Dialog, dialog.view, loading]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-column-name') {
            UpdateGridColumnName(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridColumnName, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-move') {
            UpdateGridMove(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridMove, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-rows') {
            UpdateGridRows(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridRows, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-delete') {
            UpdateGridDelete(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridDelete, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-add') {
            UpdateGridAdd(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridAdd, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'grid-reorder') {
            UpdateGridReorder(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateGridReorder, setWsResponse]);

    useEffect(() => {
        const item = {...workspaceItem};

        if (item.data.rows && item.data.columns) {

            setRows(item.data.rows);

            if (item.data.columns.length !== 0) {

                item.data.columns = item.data.columns.map((item, index) =>
                    index === 0 ? item : { ...item, renderEditCell: textEditor }
                );

            }

            setColumns(item.data.columns);

        }
    }, []);

    return (
        <>
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-grid-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-grid-col-end">
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => ButtonUpdate('accept')} >
                                {locale.dashboardWorkspaceGrid.c}
                            </Button>
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => ButtonUpdate('reject')} >
                                {locale.dashboardWorkspaceGrid.d}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-grid-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-grid-col-end">
                            {!Empty(update) &&
                                <>
                                    {update.type === 'column' &&
                                        <Button id={'dashboardGridMove'} color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => Dialog({view: ('dashboardGridRename')})} >
                                            {width > 768 ? locale.dashboardWorkspaceGrid.i : <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faPenToSquare} />}
                                        </Button>
                                    }
                                    <Button id={'dashboardGridMove'} color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => Dialog({view: ('dashboardGridMove')})} >
                                        {width > 768 ? locale.dashboardWorkspaceGrid.h : <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faGridDividers} />}
                                    </Button>
                                    <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => ButtonGridDelete()} >
                                        {width > 768 ? locale.dashboardWorkspaceGrid.g : <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faBorderNone} />}
                                    </Button>
                                    <Button id={'dashboardGridAdd'} color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => Dialog({view: ('dashboardGridAdd')})} >
                                        {width > 768 ? locale.dashboardWorkspaceGrid.f : <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faGrid2Plus} />}
                                    </Button>
                                    <Button color="secondary" variant="flat" className="cs-dashboard-header-cursor" >
                                        <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faPipe} />
                                    </Button>
                                </>
                            }
                            <Button id={'dashboardGridShare'} color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-grid-color" onClick={() => ButtonGridDownload()} >
                                {width > 768 ? locale.dashboardWorkspaceGrid.e : <FontAwesomeIcon className="cs-dashboard-workspace-grid-icon" icon={faFileCsv} />}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-grid-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-grid-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceGrid.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceGrid.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-grid-charts">
                    <div className="mbsc-col cs-dashboard-workspace-grid-col">
                        <DataGrid
                            className={"rdg-light"}
                            columns={columns}
                            rows={rows}
                            onCellClick={DatagridRowsSelect}
                            onColumnsReorder={DatagridColumnsReorder}
                            onSortColumnsChange={DatagridColumnsChange}
                            onSelectedCellChange={DatagridSelectReset}
                            onRowsChange={DatagridRowsChange}
                            onColumnResize={(idx, width) => DatagridColumnsResize(idx, width)}
                        />
                    </div>
                </div>
            }
            {workspaceItem.update && menu === 'original' &&
                <div className="mbsc-row cs-dashboard-workspace-grid-charts">
                    <div className="mbsc-col cs-dashboard-workspace-grid-col">
                        <DataGrid
                            className={"rdg-light"}
                            columns={columns}
                            rows={rows}
                        />
                    </div>
                </div>
            }
            {workspaceItem.update && menu === 'update' &&
                <div className="mbsc-row cs-dashboard-workspace-grid-charts">
                    <div className="mbsc-col cs-dashboard-workspace-grid-col">
                        <DataGrid
                            className={"rdg-light"}
                            columns={workspaceItem.data.update.columns}
                            rows={workspaceItem.data.update.rows}
                        />
                    </div>
                </div>
            }
            {dialog.view === ('dashboardGridAdd') &&
                <DashboardGridAdd
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setLoading={setLoading}
                    id={workspaceItem.id}
                    update={update}
                    setUpdate={setUpdate}
                />
            }
            {dialog.view === ('dashboardGridMove') &&
                <DashboardGridMove
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    id={workspaceItem.id}
                    update={update}
                    rows={rows.length}
                    columns={columns}
                    setLoading={setLoading}
                />
            }
            {dialog.view === ('dashboardGridColumnNew') &&
                <DashboardGridColumnNew
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setLoading={setLoading}
                    setToast={setToast}
                    width={width}
                    update={update}
                />
            }
            {dialog.view === ('dashboardGridRename') &&
                <DashboardGridRename
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setLoading={setLoading}
                    id={workspaceItem.id}
                    setToast={setToast}
                    width={width}
                    update={update}
                    columns={columns}
                />
            }
        </>
    );
}

export default DashboardWorkspaceGrid;
