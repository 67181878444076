import React, {useCallback, useEffect, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderMenu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxOpen,
    faCalendars,
    faLanguage,
    faRightFromBracket,
    faUserVneckHair,
    faMobile,
    faObjectExclude
} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderMenu({Dialog, dialog, locale, Logout, user, configuration, setLocale, setUser, setToast, Navigation, toast, system, navigation, functions, model}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const menu = useRef([{user: true}, {mobile: true}, {archive: true}, {tools: true}, {calendar: true}, {language: true}, {logout: true}]);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonDisabled = data => {
        let item = false;
        if (!Empty(toast) || system || (data && navigation.card === data)) {
            item = true;
        }
        return item;
    };

    const FunctionsButton = data => functions.includes(data);

    const ToolsSelect = data => {
        return configuration.mobi.model.find(item => item.value === data)?.tools || null;
    }

    const ListItem = data => {
        let item = '';

        if (data.item.user) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                    <Button color="primary" variant="flat" disabled={!configuration.ui.headerMenu.user} className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderUser'})} >
                        {user.avatar ? <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className="cs-dashboard-header-menu-avatar" /> : <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faUserVneckHair} />} {configuration.ui.headerMenu.user ? locale.dashboardHeaderMenu.a : ButtonUser()}
                    </Button>
            </li>;
        } else if (data.item.mobile && user.type === 'mobile') {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderMobile'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faMobile} /> {locale.dashboardHeaderMenu.h}
                </Button>
            </li>;
        } else if (data.item.archive) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderConversations'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faBoxOpen} /> {locale.dashboardHeaderMenu.f}
                </Button>
            </li>;
        } else if (data.item.tools && ToolsSelect(model) && configuration.ui.dashboard.functions && configuration.mobi.functions.length !== 0) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderTools'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faObjectExclude} /> {locale.dashboardHeaderMenu.i}
                </Button>
            </li>;
        } else if (data.item.calendar && FunctionsButton('calendar')) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button disabled={ButtonDisabled('calendar')} color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => ButtonCalendar()} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faCalendars} /> {locale.dashboardHeaderMenu.g}
                </Button>
            </li>;
        } else if (data.item.language) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                    <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => {user.locale === 'en' ? ButtonLocale('es') : ButtonLocale('en')}} >
                        <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faLanguage} /> {user.locale === 'en' ? locale.dashboardHeaderMenu.c : locale.dashboardHeaderMenu.b}
                    </Button>
            </li>;
        } else if (data.item.logout) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                    <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Logout()} >
                        <FontAwesomeIcon className="cs-dashboard-header-menu-icon-danger" icon={faRightFromBracket} /> {locale.dashboardHeaderMenu.d}
                    </Button>
            </li>;
        }

        return item;
    }

    const UserMobile = data => {
        let formattedText = '';

        if (user.locale === 'en') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 3) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
            }
        } else if (user.locale === 'es') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 2) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
            }
        }
        return formattedText;
    }

    const ButtonUser = () => {
        if (user.type === 'mobile') {
            return UserMobile(user.id);
        } else {
            return user.id;
        }
    }

    const ButtonLocale = data => {
        DialogClose();

        if (data === 'es') {
            setLocale(configuration.locale.es);
            window.localStorage.locale = 'es';
        } else if (data === 'en') {
            setLocale(configuration.locale.en);
            window.localStorage.locale = 'en';
        }

        const item = {...user};
        item.locale = data;
        setUser(item);

        const message = {
            type: 'wss',
            path: 'user-locale',
            action: 'update',
            data: {
                locale: data
            }
        };
        wsCall(message);
    }

    const ButtonCalendar = () => {
        Navigation({card: 'calendar'});
        DialogClose();
    }

    const UpdateUserLocale = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderMenu.e, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardHeaderMenu.e]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-locale') {
            UpdateUserLocale(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserLocale, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-menu-popup" display="anchored" anchor={document.getElementById('dashboardHeaderMenu')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderMenu'} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardHeaderMenu;
