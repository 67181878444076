import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, setOptions, Button, Checkbox, Textarea, Select, confirm, toast as mbToast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderMobile.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderMobile({Dialog, dialog, locale, configuration, width, toast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [prompt, setPrompt] = useState(null);
    const [override, setOverride] = useState(null);
    const [model, setModel] = useState(null);
    const [functions, setFunctions] = useState([]);
    const [messages, setMessages] = useState(0);
    const [workspace, setWorkspace] = useState(0);
    const [personality, setPersonality] = useState(null);
    const [voice, setVoice] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Settings = () => {

        const message = {
            type: 'wss',
            path: 'mobile-settings',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ButtonClose = () => {
        const message = {
            type: 'wss',
            path: 'mobile-settings',
            action: 'put',
            data: {
                prompt: prompt,
                promptOverride: override,
                model: model,
                functions: functions,
                personality: personality,
                voice: voice
            }
        };
        wsCall(message);
    }

    const ButtonConversation = () => {
        confirm({
            title: locale.dashboardHeaderMobile.e,
            message: locale.dashboardHeaderMobile.j,
            okText: locale.dashboardHeaderMobile.h,
            cancelText: locale.dashboardHeaderMobile.i,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'mobile-conversation-reset',
                        action: 'update',
                        data: {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonWorkspace = () => {
        confirm({
            title: locale.dashboardHeaderMobile.g,
            message: locale.dashboardHeaderMobile.k,
            okText: locale.dashboardHeaderMobile.h,
            cancelText: locale.dashboardHeaderMobile.i,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'mobile-workspace-reset',
                        action: 'update',
                        data: {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Avatar = () => {
        const itemAvatar = configuration.mobi.personalities.find(item => item.value === personality);

        return "url(" + ((voice === 'nova' || voice === 'shimmer') ? itemAvatar.avatarFemale : itemAvatar.avatarMale) + ")";
    }

    const GetMobileSettings = useCallback(data => {
        if (data.prompt) {
            setPrompt(data.prompt);
        }
        if (data.promptOverride === true || data.promptOverride === false) {
            setOverride(data.promptOverride);
        }
        if (data.model) {
            setModel(data.model);
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
        if (data.messages) {
            setMessages(data.messages);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.voice) {
            setVoice(data.voice);
        }
        if (data.personality) {
            setPersonality(data.personality);
        }
    }, []);

    const PutMobileSettings = useCallback(data => {
        if (data.toast) {
            DialogClose();
        }
    }, [DialogClose]);

    const UpdateMobileConversationReset = useCallback(data => {
        if (data.toast) {
            mbToast({message: locale.dashboardHeaderMobile.l, color: 'info', duration: 1000, display: 'bottom'});
            setMessages(0);
        }
    }, [locale.dashboardHeaderMobile.l]);

    const UpdateMobileWorkspaceReset = useCallback(data => {
        if (data.toast) {
            mbToast({message: locale.dashboardHeaderMobile.m, color: 'info', duration: 1000, display: 'bottom'});
            setWorkspace(0);
        }
    }, [locale.dashboardHeaderMobile.m]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'mobile-conversation-reset') {
            UpdateMobileConversationReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateMobileConversationReset, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'mobile-workspace-reset') {
            UpdateMobileWorkspaceReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateMobileWorkspaceReset, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'mobile-settings') {
            PutMobileSettings(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutMobileSettings, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'mobile-settings') {
            GetMobileSettings(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetMobileSettings, setWsResponse]);

    useEffect(() => {
        Settings();
    }, []);

    return (
        <Popup className="cs-dashboard-header-mobile-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardHeaderMobile'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-mobile-header-col">
                <div className="mbsc-row cs-dashboard-header-mobile-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-mobile-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-mobile-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-mobile-header-col"} >
                        <Button className="cs-dashboard-header-mobile-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderMobile.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-mobile-header-col mbsc-align-right">
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-mobile-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-mobile-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {personality &&
                <div className="mbsc-grid cs-dashboard-header-mobile-header-col">
                    <div className="mbsc-row cs-dashboard-header-mobile-header-row mbsc-justify-content-center">
                        <div style={{backgroundImage: (!override ? Avatar() : null)}} className="cs-dashboard-header-mobile-avatar" />
                    </div>
                </div>
            }
            <Select
                disabled
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderMobile.o}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.personalities.length}
                display="anchored"
                data={configuration.mobi.personalities}
                value={personality}
                onChange={args => setPersonality(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderMobile.n}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.voice.length}
                display="anchored"
                data={configuration.mobi.voice}
                value={voice}
                onChange={args => setVoice(args.value)}
            />
            {configuration.ui.dashboard.model &&
                <Select
                    animation="slide-down"
                    inputStyle="underline"
                    dropdown={false}
                    label={locale.dashboardHeaderMobile.b}
                    labelStyle="floating"
                    rows={configuration.mobi.modelMobile.length}
                    display="anchored"
                    touchUi={false}
                    data={configuration.mobi.modelMobile}
                    value={model}
                    onChange={args => setModel(args.value)}
                />
            }
            <div className="cs-dashboard-header-mobile-helper">
                <p>{locale.dashboardHeaderMobile.d}</p>
            </div>
            <div className="cs-dashboard-header-mobile-textarea">
                <Textarea
                    inputStyle="underline"
                    labelStyle="floating"
                    rows={3}
                    value={prompt}
                    onChange={(ev) => setPrompt(ev.target.value)}
                />
            </div>
            {configuration.ui.dashboard.override &&
                <div className="mbsc-grid cs-dashboard-header-mobile-main">
                    <Checkbox
                        cssClass="cs-dashboard-header-mobile-checkbox mbsc-bold"
                        color="info"
                        position="end"
                        label={locale.dashboardHeaderMobile.c}
                        checked={override}
                        onChange={(ev) => setOverride(ev.target.checked)}
                    />
                </div>
            }
            {configuration.ui.dashboard.functions &&
                <Select
                    animation="slide-down"
                    inputStyle="underline"
                    dropdown={false}
                    placeholder={locale.dashboardHeaderMobile.f}
                    labelStyle="floating"
                    touchUi={false}
                    rows={configuration.mobi.functions.length}
                    display="anchored"
                    selectMultiple={true}
                    value={functions}
                    data={configuration.mobi.functions}
                    onChange={args => setFunctions(args.value)}
                />
            }
            <div className="mbsc-button-group-justified cs-dashboard-header-mobile-nav-buttons">
                <Button disabled={messages === 0} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-mobile-nav-button" onClick={() => ButtonConversation()} >
                    {locale.dashboardHeaderMobile.e}
                </Button>
                <Button disabled={workspace === 0} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-mobile-nav-button" onClick={() => ButtonWorkspace()} >
                    {locale.dashboardHeaderMobile.g}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardHeaderMobile;
