import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceAddName.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceAddName({locale, dialog, Dialog, width, workspaceItemDialog, setWorkspaceItemDialog, setWorkspace}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [name, setName] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!name) {
            toast({message: locale.dashboardWorkspaceAddName.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {
            const item = {...workspaceItemDialog};
            item.name = name;

            const message = {
                type: 'wss',
                path: item.type === 'file' ? 'workspace-file-generate' : 'workspace-item',
                action: 'put',
                data: item
            };
            wsCall(message);
        }
    }

    const PutWorkspaceItem = useCallback(data => {
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        setWorkspaceItemDialog({});
        DialogClose();
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'workspace-item') {
            PutWorkspaceItem(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutWorkspaceItem, setWsResponse]);

    return (
        <Popup className="cs-dashboard-workspace-add-name-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceAddName'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-workspace-add-name-header-col">
                <div className="mbsc-row cs-dashboard-workspace-add-name-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-add-name-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-add-name-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-add-name-header-col"} >
                        <Button className="cs-dashboard-workspace-add-name-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardWorkspaceAddName.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-add-name-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-add-name-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-add-name-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardWorkspaceAddName.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => setName(ev.target.value)} />
            <div className="mbsc-button-group-justified cs-dashboard-workspace-add-name-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-add-name-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardWorkspaceAddName.d}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardWorkspaceAddName;
