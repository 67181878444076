import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions, confirm, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceTodo.css';
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faPipe, faCircleCheck, faArrowDownWideShort, faArrowUpShortWide} from "@fortawesome/pro-duotone-svg-icons";
import DashboardWorkspaceTodoEdit from "../dialogs/DashboardWorkspaceTodoEdit";
import DashboardWorkspaceTodoNew from "../dialogs/DashboardWorkspaceTodoNew";
import Truncate from "../utils/Truncate";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceTodo({locale, workspaceItem, setLoading, Dialog, width, dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [todos, setTodos] = useState(workspaceItem.data.todos);
    const [results, setResults] = useState(workspaceItem.data.todos);
    const [todo, setTodo] = useState({});
    const [menu, setMenu] = useState('original');
    const [instance, setInstance] = useState({});
    const [target, setTarget] = useState(null);
    const [text, setText] = useState(null);
    const [sort, setSort] = useState(true);

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestamp)).format("YYYY-M-D");

        return <li data-id={data.item.timestamp} >
            <div className="mbsc-row cs-dashboard-workspace-todo-list" >
                {data.item.complete ?
                    <FontAwesomeIcon className="cs-dashboard-workspace-todo-icon" icon={faCircleCheck} />
                    :
                    <FontAwesomeIcon className="cs-dashboard-workspace-todo-icon" icon={faCircleNotch} />
                }

                <div className="cs-dashboard-workspace-todo-list-section">
                    <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.name, 25) : Truncate(data.item.name, 60)}</div>
                    <div className="mbsc-row mbsc-txt-s cs-dashboard-workspace-todo-item-txt">
                        <span className="mbsc-txt-muted">{width < 992 ? Truncate(data.item.detail, 25) : Truncate(data.item.detail, 60)}</span>
                        <FontAwesomeIcon className="cs-dashboard-workspace-todo-pipe" icon={faPipe} />
                        <span className="mbsc-txt-muted">{itemDate}</span>
                    </div>
                </div>
            </div>
        </li>;
    }

    const ListTap = data => {
        setTarget(results[data.index].timestamp);
    }

    const ListComplete = () => {
        if (target) {
            const item = todos.find(itemTodo => itemTodo.timestamp === target);
            if (item && item.complete) {
                return locale.dashboardWorkspaceTodo.h;
            } else {
                return locale.dashboardWorkspaceTodo.e;
            }
        } else {
            return locale.dashboardWorkspaceTodo.e;
        }
    }

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const ButtonNew = () => {
        Dialog({view: 'dashboardWorkspaceTodoNew'});
    }

    const ButtonSort = () => {
        if (sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
            setResults(array);
        } else if (!sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
            setResults(array);
        }
        if (sort && todos.length !== 0) {
            const array = todos;
            array.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
            setTodos(array);
        } else if (!sort && todos.length !== 0) {
            const array = todos;
            array.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
            setTodos(array);
        }
        if (sort) {
            setSort(false);
        } else {
            setSort(true);
        }
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    const InputSuggestion = data => {
        setText(data);
        const itemValue = data;
        let itemResults = todos;

        if (itemValue && itemResults.length !== 0) {
            itemResults = itemResults.filter(item => {
                const itemText = item.name + ' ' + item.detail;

                return itemText.toLowerCase().includes(itemValue.toLowerCase())
            });
        }
        if (!itemValue && itemResults.length === 0){
            setResults([]);
        } else {
            setResults(itemResults);
        }
    };

    const UpdateTodo = useCallback(data => {
        if (data.todos){
            setTodos(data.todos);
            setResults(data.todos);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [Dialog, dialog.view]);

    const DeleteTodo = useCallback(data => {
        if (data.todos){
            setTodos(data.todos);
            setResults(data.todos);
        }
    }, []);

    const PutTodo = useCallback(data => {
        if (data.todos){
            setTodos(data.todos);
            setResults(data.todos);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [Dialog, dialog.view]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'todo') {
            PutTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutTodo, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'todo') {
            DeleteTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTodo, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'todo') {
            UpdateTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateTodo, setWsResponse]);

    useEffect(() => {
        if (target) {
            instance.openActions(target, 'left');
        }
    }, [instance, target]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-workspace-todo-header-secondary" >
                <div className="mbsc-col cs-dashboard-workspace-todo-header-col cs-dashboard-workspace-todo-select" >
                    <Input
                        disabled={todos.length === 0 || workspaceItem.update}
                        inputStyle="underline"
                        placeholder={locale.dashboardWorkspaceTodo.m}
                        labelStyle="floating"
                        type="text"
                        name="search"
                        value={text}
                        onChange={ev => InputSuggestion(ev.target.value)}
                    />
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row cs-dashboard-workspace-todo-col-end">
                        {workspaceItem.update &&
                            <>
                                <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-todo-color" onClick={() => ButtonUpdate('accept')} >
                                    {locale.dashboardWorkspaceTodo.c}
                                </Button>
                                <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-todo-color" onClick={() => ButtonUpdate('reject')} >
                                    {locale.dashboardWorkspaceTodo.d}
                                </Button>
                            </>
                        }
                        {!workspaceItem.update &&
                            <>
                                <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-todo-color" onClick={() => ButtonNew()} >
                                    {locale.dashboardWorkspaceTodo.i}
                                </Button>
                                <Button disabled={todos.length === 0} className="mbsc-bold" variant="flat" onClick={() => ButtonSort()} >
                                    <FontAwesomeIcon className="cs-dashboard-workspace-todo-sort-button" icon={sort ? faArrowDownWideShort : faArrowUpShortWide} />
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-todo-section">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-todo-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceTodo.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceTodo.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    onItemTap={ListTap}
                    data={results}
                    onInit={(event, inst) => setInstance(inst)}
                    actionsWidth={100}
                    actions={{
                        right: [{
                            color: '#0B7DBB',
                            text: ListComplete(),
                            action: (event, inst) => {
                                const itemTodo = todos[event.index];
                                itemTodo.complete = !itemTodo.complete;

                                const message = {
                                    type: 'wss',
                                    path: 'todo',
                                    action: 'update',
                                    data: {
                                        item:  workspaceItem.id,
                                        todo: itemTodo
                                    }
                                };
                                wsCall(message);

                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardWorkspaceTodo.f,
                            action: (event, inst) => {

                                const itemTodo = todos[event.index];
                                setTodo(itemTodo);
                                Dialog({view: 'dashboardWorkspaceTodoEdit'});

                                inst.close(event.target);

                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardWorkspaceTodo.g,
                            action: (event, inst) => {
                                confirm({
                                    title: locale.dashboardWorkspaceTodo.j,
                                    message: locale.dashboardWorkspaceTodo.k,
                                    okText: locale.dashboardWorkspaceTodo.g,
                                    cancelText: locale.dashboardWorkspaceTodo.l,
                                    callback: (res) => {
                                        if (res) {
                                            const itemTodo = todos[event.index].timestamp;

                                            const message = {
                                                type: 'wss',
                                                path: 'todo',
                                                action: 'delete',
                                                data: {
                                                    item:  workspaceItem.id,
                                                    todo: itemTodo
                                                }
                                            };
                                            wsCall(message);
                                        } else {
                                            inst.close(event.target);
                                        }
                                    }
                                });
                                return false;
                            }
                        }]
                    }}
                />
            }
            {workspaceItem.update && menu === 'original' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={todos}
                />
            }
            {workspaceItem.update && menu === 'update' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={workspaceItem.data.update.todos}
                />
            }
            {dialog.view === 'dashboardWorkspaceTodoEdit' &&
                <DashboardWorkspaceTodoEdit
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    todo={todo}
                    workspaceItem={workspaceItem.id}
                />
            }
            {dialog.view === 'dashboardWorkspaceTodoNew' &&
                <DashboardWorkspaceTodoNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    workspaceItem={workspaceItem.id}
                />
            }
        </>
    );
}

export default DashboardWorkspaceTodo;
