import React, {useCallback, useContext, useEffect} from 'react';
import {Button, confirm, setOptions, Snackbar} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {BalloonEditor, Autosave, BlockQuote, BlockToolbar, Bold, Essentials, Italic, Paragraph, RemoveFormat, Subscript, Superscript, Underline, CodeBlock} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../styles/dashboardQuestion.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faArrowRotateRight, faTrashCan, faBoxTaped, faObjectsColumn, faGearCode} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import DashboardQuestionSettings from "../dialogs/DashboardQuestionSettings";
import DashboardQuestionSave from "../dialogs/DashboardQuestionSave";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestion({width, configuration, Dialog, dialog, toast, system, locale, setModel, model, functions, text, setText, setToast, setMessages, Navigation, loading, messages, tabs, setSystem, setSnackbar, setWorkspace, workspace, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const EditorUpdate = data => {
        let item = data;
        if (!item) {
            item = '<p></p>'
        }
        setText(item);
        window.localStorage.text = item;
    }

    const ButtonDelete = data => {
        confirm({
            title: locale.dashboardQuestion.ae,
            message: locale.dashboardQuestion.af,
            okText: locale.dashboardQuestion.x,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.ag, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'tab',
                        action: 'delete',
                        data: {
                            tabs: tabs
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonMessage = () => {
        setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'update',
            data: {
                text: text,
                model: model,
                functions: functions
            }
        };
        wsCall(message);
    }

    const ButtonRestart = () => {
        confirm({
            title: locale.dashboardQuestion.ab,
            message: locale.dashboardQuestion.ac,
            okText: locale.dashboardQuestion.aa,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.z, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'conversation-reset',
                        action: 'update',
                        data: {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonWorkspace = () => {
        if (navigation.card === 'workspace') {
            Navigation({card: 'close'});
        } else {
            Navigation({card: 'workspace'});
        }
    }

    const ButtonSystem = () => {
        setSystem(false);

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {
                system: false
            }
        };
        wsCall(message);
    }

    const UpdateConversationReset = useCallback(data => {
        if (data.toast) {
            setMessages([]);
            setText('<p></p>');
            window.localStorage.text = '<p></p>';
        }
    }, []);

    const UpdateConversation = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
            setText('<p></p>');
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.error) {
            confirm({
                title: locale.dashboardQuestion.g,
                message: locale.dashboardQuestion.h,
                okText: locale.dashboardQuestion.i,
                cancelText: locale.dashboardQuestion.l,
                callback: (res) => {
                    if (res) {
                        setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});

                        const message = {
                            type: 'wss',
                            path: 'conversation-error',
                            action: 'update',
                            data: {}
                        };
                        wsCall(message);
                    }
                }
            });
        }
        Navigation({card: 'close'});
        window.scrollTo(0, 0);
    }, [Navigation, locale.dashboardQuestion.g, locale.dashboardQuestion.h, locale.dashboardQuestion.i, locale.dashboardQuestion.l, locale.dashboardQuestion.v]);

    const UpdateConversationError = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
        }
    }, []);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-reset') {
            UpdateConversationReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationReset, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation') {
            UpdateConversation(wsResponse.data);
            setWsResponse({});
            setToast({});
            setSnackbar({});
        }
    }, [wsResponse, UpdateConversation, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-error') {
            UpdateConversationError(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, UpdateConversationError, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-question-header" >
                <div className="mbsc-col" />
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-question-col-end">
                        <Button disabled={messages === 0 || !Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonRestart()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faArrowRotateRight} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.w}</div>}
                        </Button>
                        <Button disabled={!Empty(toast) || messages === 0 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardQuestionSave'})} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faBoxTaped} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.d}</div>}
                        </Button>
                        <Button disabled={!Empty(toast) || tabs.length < 2 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonDelete()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faTrashCan} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.x}</div>}
                        </Button>
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardQuestionSettings'})} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faGearCode} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-question-main">
                <div className="mbsc-col cs-dashboard-question-body">
                    <CKEditor
                        editor={BalloonEditor}
                        config={{
                            toolbar: {
                                items: ['bold', 'italic', 'underline', 'subscript', 'superscript', 'removeFormat', '|', 'blockQuote', 'codeBlock'],
                                shouldNotGroupWhenFull: false
                            },
                            blockToolbar: ['bold', 'italic', '|', 'blockQuote', 'codeBlock'],
                            versionCheck: false,
                            plugins: [
                                Autosave,
                                BlockQuote,
                                BlockToolbar,
                                Bold,
                                Essentials,
                                Italic,
                                Paragraph,
                                RemoveFormat,
                                Subscript,
                                Superscript,
                                Underline,
                                CodeBlock
                            ],
                            licenseKey: 'GPL',
                            ui: {
                                poweredBy: {position:'inside', side:'right'}
                            }
                        }}
                        data={text}
                        onChange={(event, editor) => {EditorUpdate(editor.getData())}}
                        disableWatchdog={true}
                    />
                </div>
            </div>
            {!loading &&
                <div className="mbsc-row cs-dashboard-question-footer">
                    <div className="mbsc-col" >
                        <div className="mbsc-row cs-dashboard-question-col-start">
                            <Button disabled={Boolean(!Empty(toast) || system)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonWorkspace()} >
                                <div className="cs-dashboard-question-badge">
                                    <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faObjectsColumn} />
                                    {workspace.length > 0 && (
                                        <span className="cs-dashboard-question-badge-icon">{workspace.length}</span>
                                    )}
                                </div>
                                {width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.f}</div>}
                            </Button>
                        </div>
                    </div>
                    <div className="mbsc-col-auto">
                        <div className="mbsc-row cs-dashboard-question-col-end">
                            <Button disabled={!Empty(toast) || system || !text || text === '<p></p>'} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonMessage()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faPaperPlane} /><div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.a}</div>
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {dialog.view === 'dashboardQuestionSettings' &&
                <DashboardQuestionSettings
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    width={width}
                    toast={toast}
                    system={system}
                    setSystem={setSystem}
                    setModel={setModel}
                    model={model}
                />
            }
            {dialog.view === 'dashboardQuestionSave' &&
                <DashboardQuestionSave
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setToast={setToast}
                    width={width}
                />
            }
            <Snackbar
                cssClass="cs-dashboard-question-snackbar mbsc-bold"
                isOpen={system}
                message={locale.dashboardQuestion.b}
                duration={false}
                color="primary"
                display="bottom"
                button={{
                    text: locale.dashboardQuestion.c,
                    action: () => {
                        ButtonSystem()
                    }
                }}
            />
        </>
    );
}

export default DashboardQuestion;
