import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Textarea, Button, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceGenerate.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceGenerate({Dialog, dialog, locale, setToast, toast, width, setWorkspaceItemDialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [image, setImage] = useState({});
    const [prompt, setPrompt] = useState(null);
    const [resolution, setResolution] = useState(locale.dashboardWorkspaceGenerate.m[0].value);
    const [quality, setQuality] = useState(locale.dashboardWorkspaceGenerate.l[0].value);
    const [style, setStyle] = useState(locale.dashboardWorkspaceGenerate.k[0].value);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonGenerate = () => {
        if (!prompt) {
            toast({message: locale.dashboardWorkspaceGenerate.a, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardWorkspaceGenerate.g, color: 'primary', display: 'center', duration: false});
            setImage('');

            const message = {
                type: 'wss',
                path: 'dalle',
                action: 'get',
                data: {
                    prompt: prompt,
                    resolution: resolution,
                    quality: quality,
                    style: style
                }
            };
            wsCall(message);
        }
    }

    const ButtonWorkspace = () => {
        const item = {...image};
        item.type = 'file';
        setWorkspaceItemDialog(item);
        Dialog({view: 'dashboardWorkspaceAddName'});
    }

    const GetDalle = useCallback(data => {
        if (data.image) {
            setImage(data.image);
            setPrompt(data.image.prompt);
        }
        if (data.error) {
            setToast({message: data.error, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'dalle') {
            GetDalle(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, GetDalle, setWsResponse]);

    return (
        <Popup className="cs-dashboard-workspace-generate-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceGenerate'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-workspace-generate-header-col">
                <div className="mbsc-row cs-dashboard-workspace-generate-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-generate-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-generate-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-generate-header-col"} >
                        <Button className="cs-dashboard-workspace-generate-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardWorkspaceGenerate.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-generate-header-col mbsc-align-right">
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-generate-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-generate-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-workspace-generate-helper">
                <p>{!Empty(image) ? locale.dashboardWorkspaceGenerate.n : locale.dashboardWorkspaceGenerate.e}</p>
            </div>
            <div className="cs-dashboard-workspace-generate-textarea">
                <Textarea
                    inputStyle="underline"
                    labelStyle="floating"
                    rows={3}
                    value={prompt}
                    onChange={(ev) => setPrompt(ev.target.value)}
                />
            </div>
            {!Empty(image) &&
                <div style={{backgroundImage: `url(${image.url})`}} className="cs-dashboard-workspace-generate-image"  />
            }
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardWorkspaceGenerate.f}
                labelStyle="floating"
                rows={locale.dashboardWorkspaceGenerate.m.length}
                display="anchored"
                touchUi={false}
                data={locale.dashboardWorkspaceGenerate.m}
                value={resolution}
                onChange={args => setResolution(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardWorkspaceGenerate.i}
                labelStyle="floating"
                rows={locale.dashboardWorkspaceGenerate.l.length}
                display="anchored"
                touchUi={false}
                data={locale.dashboardWorkspaceGenerate.l}
                value={quality}
                onChange={args => setQuality(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardWorkspaceGenerate.j}
                labelStyle="floating"
                rows={locale.dashboardWorkspaceGenerate.k.length}
                display="anchored"
                touchUi={false}
                data={locale.dashboardWorkspaceGenerate.k}
                value={style}
                onChange={args => setStyle(args.value)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-workspace-generate-nav-buttons">
                <Button disabled={Empty(image)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-generate-nav-button" onClick={() => ButtonWorkspace()} >
                    {locale.dashboardWorkspaceGenerate.h}
                </Button>
                <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-generate-nav-button" onClick={() => ButtonGenerate()} >
                    {locale.dashboardWorkspaceGenerate.b}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardWorkspaceGenerate;
