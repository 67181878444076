import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, setOptions, Button, Checkbox, Textarea, Select} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardQuestionSettings.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestionSettings({Dialog, dialog, locale, configuration, width, toast, system, setSystem, model, setModel}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [prompt, setPrompt] = useState(null);
    const [override, setOverride] = useState(null);
    const [personality, setPersonality] = useState(null);
    const [voice, setVoice] = useState(null);
    const [ai, setAi] = useState(model);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Settings = () => {

        const message = {
            type: 'wss',
            path: 'conversation-settings',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const CheckboxSystem = () => {

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {}
        };
        if (system) {
            setSystem(false);
            message.data.system = false;
        } else {
            setSystem(true);
            message.data.system = true;
        }
        wsCall(message);
    }

    const ButtonClose = () => {
        setModel(ai);

        const message = {
            type: 'wss',
            path: 'conversation-settings',
            action: 'put',
            data: {
                prompt: prompt,
                promptOverride: override,
                voice: voice,
                personality: personality,
                model: ai
            }
        };
        wsCall(message);
    }

    const Avatar = () => {
        const itemAvatar = configuration.mobi.personalities.find(item => item.value === personality);

        return "url(" + ((voice === 'nova' || voice === 'shimmer') ? itemAvatar.avatarFemale : itemAvatar.avatarMale) + ")";
    }

    const GetConversationSettings = useCallback(data => {
        if (data.prompt) {
            setPrompt(data.prompt);
        }
        if (data.promptOverride === true || data.promptOverride === false) {
            setOverride(data.promptOverride);
        }
        if (data.personality) {
            setPersonality(data.personality);
        }
        if (data.voice) {
            setVoice(data.voice);
        }
        if (data.model) {
            setModel(data.model);
        }
    }, []);

    const PutConversationSettings = useCallback(data => {
        if (data.toast) {
            DialogClose();
        }
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'conversation-settings') {
            PutConversationSettings(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutConversationSettings, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-settings') {
            GetConversationSettings(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationSettings, setWsResponse]);

    useEffect(() => {
        Settings();
    }, []);

    return (
        <Popup className="cs-dashboard-question-settings-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardQuestionSettings'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-question-settings-header-col">
                <div className="mbsc-row cs-dashboard-question-settings-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-question-settings-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-question-settings-header-col mbsc-align-center" : "mbsc-col cs-dashboard-question-settings-header-col"} >
                        <Button className="cs-dashboard-question-settings-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardQuestionSettings.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-question-settings-header-col mbsc-align-right">
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-settings-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-question-settings-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {personality &&
                <div className="mbsc-grid cs-dashboard-question-settings-header-col">
                    <div className="mbsc-row cs-dashboard-question-settings-header-row mbsc-justify-content-center">
                        <div style={{backgroundImage: (!override ? Avatar() : null)}} className="cs-dashboard-question-settings-avatar" />
                    </div>
                </div>
            }
            <Select
                disabled={override}
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardQuestionSettings.g}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.personalities.length}
                display="anchored"
                data={configuration.mobi.personalities}
                value={personality}
                onChange={args => setPersonality(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardQuestionSettings.f}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.voice.length}
                display="anchored"
                data={configuration.mobi.voice}
                value={voice}
                onChange={args => setVoice(args.value)}
            />
            {configuration.ui.dashboard.model &&
                <Select
                    animation="slide-down"
                    inputStyle="underline"
                    dropdown={false}
                    label={locale.dashboardQuestionSettings.h}
                    labelStyle="floating"
                    disabled={!Empty(toast) || system}
                    rows={configuration.mobi.model.length}
                    display="anchored"
                    touchUi={false}
                    data={configuration.mobi.model}
                    value={ai}
                    onChange={args => setAi(args.value)}
                />
            }
            <div className="cs-dashboard-question-settings-helper">
                <p>{locale.dashboardQuestionSettings.d}</p>
            </div>
            <div className="cs-dashboard-question-settings-textarea">
                <Textarea
                    inputStyle="underline"
                    labelStyle="floating"
                    rows={3}
                    value={prompt}
                    onChange={(ev) => setPrompt(ev.target.value)}
                />
            </div>
            {configuration.ui.dashboard.override &&
                <div className="mbsc-grid cs-dashboard-question-settings-main">
                    <Checkbox
                        cssClass="cs-dashboard-question-settings-checkbox mbsc-bold"
                        color="info"
                        position="end"
                        label={locale.dashboardQuestionSettings.c}
                        checked={override}
                        onChange={(ev) => setOverride(ev.target.checked)}
                    />
                </div>
            }
            {configuration.ui.dashboard.system &&
                <div className="cs-dashboard-question-settings-helper">
                    <p>{locale.dashboardQuestionSettings.e}</p>
                </div>
            }
            {configuration.ui.dashboard.system &&
                <div className="mbsc-grid cs-dashboard-question-settings-main">
                    <Checkbox
                        cssClass="cs-dashboard-question-settings-checkbox mbsc-bold"
                        color="info"
                        position="end"
                        label={locale.dashboardQuestionSettings.b}
                        checked={system}
                        onChange={(ev) => CheckboxSystem(ev.target.checked)}
                    />
                </div>
            }
        </Popup>
    );
}

export default DashboardQuestionSettings;
