import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Eventcalendar, Button, Datepicker, setOptions, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCirclePlus, faSparkles, faXmark, faPipe, faChevronLeft, faCircleNotch, faChevronRight, faCalendarRange, faCalendarLinesPen, faUserPlus, faClock} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Truncate from "../utils/Truncate";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import DashboardCalendarEvent from '../dialogs/DashboardCalendarEvent';
import DashboardCalendarNew from "../dialogs/DashboardCalendarNew";
import DashboardCalendarRange from "../dialogs/DashboardCalendarRange";
import DashboardCalendarDisplay from "../dialogs/DashboardCalendarDisplay";
import DashboardCalendarHours from "../dialogs/DashboardCalendarHours";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendar({locale, Navigation, navigation, width, calendar, setCalendar, Dialog, dialog, setToast, ui, setUi}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [refresh, setRefresh] = useState(false);
    const [info, setInfo] = useState(false);
    const [display, setDisplay] = useState(ui.calendarDisplay ? ui.calendarDisplay : 'agenda');
    const [date, setDate] = useState(dayjs().format());
    const [range, setRange] = useState(ui.calendarRange ? ui.calendarRange : 'day');
    const [startTime, setStartTime] = useState(ui.calendarStartTime ? ui.calendarStartTime : null);
    const [endTime, setEndTime] = useState(ui.calendarEndTime ? ui.calendarEndTime : null);
    const [visit, setVisit] = useState({});
    const [anchor, setAnchor] = useState(null);
    const [instance, setInstance] = useState({});

    const PickerFormat = () => {
        if (range === 'day') {
            return "YYYY-M-D";
        } else if (range === 'week') {
            return "YYYY-M-D";
        } else if (range === 'month') {
            return "YYYY-M";
        }
    }

    const PickerWheels = () => {
        if (range === 'day') {
            return "|YYYY | MMM | D|"
        } else if (range === 'week') {
            return "|YYYY | MMM | D|"
        } else if (range === 'month') {
            return "|YYYY | MMM|"
        }
    }

    const PickerUpdate = data => {
        const itemDate = dayjs(data).format("YYYY-M-D");
        setDate(itemDate);
    }

    const CalendarView = () => {
        if (display === 'agenda') {
            return {
                agenda: {
                    type: range,
                    allDay: false,
                    showEmptyDays: true,
                    startTime: startTime,
                    endTime: endTime
                }
            }
        } else if (range === 'day') {
            return {
                schedule: {
                    type: 'day',
                    allDay: false,
                    startTime: startTime,
                    endTime: endTime
                }
            }
        } else if (range === 'week') {
            return {
                schedule: {
                    type: 'week',
                    allDay: false,
                    startTime: startTime,
                    endTime: endTime
                }
            }
        } else if (range === 'month') {
            return {
                calendar: {
                    allDay: false,
                    type: 'month',
                    labels: 'all',
                    startTime: startTime,
                    endTime: endTime
                }
            }
        }
    }

    const CalendarLoading = data => {
        setToast({message: locale.dashboardCalendar.a, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'wss',
            path: 'calendar',
            action: 'get',
            data: {
                view: navigation.tab,
                start: dayjs(data.firstDay).format(),
                end: dayjs(data.lastDay).format()
            }
        };
        wsCall(message);
    }

    const CalendarCreateInline = (data, inst) => {
        setAnchor(data.target);
        setVisit({start: data.event.start, end: data.event.end, id: data.event.id});
        setInstance(inst);
        Dialog({view: 'dashboardCalendarNew'});
    }

    const CalendarOpen = data => {
        if (display === 'scheduler') {
            setAnchor(data.domEvent.target);
        } else {
            setAnchor(null);
        }
        setVisit(data.event);
        Dialog({view: 'dashboardCalendarEvent'});
    }

    const CalendarUpdate = data => {

        const message = {
            type: 'wss',
            path: 'event',
            action: 'update',
            data: {
                start: dayjs(data.event.start).format(),
                end: dayjs(data.event.end).format(),
                resource: data.event.resource,
                user: data.event.user,
                event: data.event.id
            }
        };
        wsCall(message);
    }

    const CalendarDelete = data => {

        confirm({
            title: locale.dashboardCalendar.n,
            message: locale.dashboardCalendar.o,
            okText: locale.dashboardCalendar.p,
            cancelText: locale.dashboardCalendar.q,
            callback: (res) => {
                if (res) {
                    setRefresh(true);

                    const message = {
                        type: 'wss',
                        path: 'event',
                        action: 'delete',
                        data: {
                            event: data.event
                        }
                    };
                    wsCall(message);
                }
            }
        })

        return false;
    }

    const ButtonInfo = () => {
        if (info) {
            setInfo(false);
        } else if (!info) {
            setInfo(true);
        }
    }

    const ButtonDatePlus = () => {
        const itemDate = date;
        setDate(dayjs(itemDate).add(1, 'day').format("YYYY-M-D"));
    }

    const ButtonDateMinus = () => {
        const itemDate = date;
        setDate(dayjs(itemDate).subtract(1, 'day').format("YYYY-M-D"));
    }

    const ButtonDateToday = () => {
        setDate(dayjs().format("YYYY-M-D"));
    }

    const ButtonRange = () => {
        if (range === 'week') {
            return locale.dashboardCalendar.i;
        } else if (range === 'month') {
            return locale.dashboardCalendar.j;
        } else if (range === 'day') {
            return locale.dashboardCalendar.h;
        }
    }

    const ButtonCreate = () => {
        setAnchor(null);
        setVisit({});
        setInstance({});
        Dialog({view: 'dashboardCalendarNew'});
    }

    const ButtonDisplay = () => {
        if (display === 'agenda') {
            return locale.dashboardCalendar.k;
        } else if (display === 'scheduler') {
            return locale.dashboardCalendar.l;
        }
    }

    const EventInitials = data => {
        if (!data) return '';

        return data
            .trim()
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(word => word[0])
            .join('')
            .toUpperCase();
    }

    const EventContent = data => {
        let itemTitle = data.original.title;
        let itemAge = '';
        let itemGender = '';
        let itemReturn = '';

        if (data.original.dob) {
            const today = dayjs();
            const birthDate = dayjs(data.original.dob);
            const age = today.diff(birthDate, 'year');

            itemAge = age + ' ' + locale.calendar.q;
        }
        if (data.original.gender) {
            itemGender = data.original.gender === 'male' ? locale.calendar.r : locale.calendar.s;
        }

        if (display === 'agenda' ||
            (display === 'scheduler' && range === 'day')) {
            itemReturn = <>
                <div>
                    {itemTitle}
                    {(itemAge || itemGender) && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
                    {itemAge && itemAge}
                    {(itemAge && itemGender) && ', '}
                    {itemGender && itemGender}
                    {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
                    {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
                </div>
                {data.original.reason &&
                    <div className="cs-calendar-event-secondary">{Truncate(data.original.reason, 30)}</div>
                }
            </>
        } else if (display === 'scheduler' && range === 'week') {
            itemReturn = <>
                <div>
                    {itemTitle}
                    {data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
                    {data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
                </div>
                {data.original.reason &&
                    <div className="cs-calendar-event-secondary">{Truncate(data.original.reason, 15)}</div>
                }
            </>
        } else if (display === 'scheduler' && range === 'month') {
            itemReturn = <>
                <div style={{background: data.original.color + '80', color: '#000'}} className="cs-calendar-event-month mbsc-bold">
                    {width < 768 ? EventInitials(itemTitle) : itemTitle}
                    {width > 992 && data.original.firstVisit && <FontAwesomeIcon className="cs-calendar-event-icon-pipe-xs" icon={faPipe} />}
                    {width > 992 && data.original.firstVisit && <><FontAwesomeIcon className="cs-calendar-event-icon-info-xs" icon={faUserPlus} /></>}
                </div>
            </>
        }

        return itemReturn;
    }

    const StyleSectionMenu = () => {
        if (display === 'agenda') {
            return "mbsc-row cs-dashboard-calendar-main";
        } else if (display !== 'agenda') {
            return "mbsc-row cs-dashboard-calendar-submenu";
        }
    }

    const GetCalendar = useCallback(data => {
        if (data.calendar) {
            setCalendar(data.calendar);
        }
    }, []);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'calendar') {
            GetCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetCalendar, setWsResponse]);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-calendar-header">
                <div className="mbsc-col cs-dashboard-calendar-col-start">
                    <Button onClick={() => ButtonInfo()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" >
                        <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faSparkles} />{width > 768 && '\u00A0'}
                        {info &&
                            <div className="cs-dashboard-calendar-menu-text">
                                {locale.dashboardCalendar.d}
                            </div>
                        }
                    </Button>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-calendar-col-end">
                        <Button id="dashboardCalendarHours" color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => Dialog({view: 'dashboardCalendarHours'})} >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faClock} />{width > 768 && <div className="cs-dashboard-calendar-menu-text">{locale.dashboardCalendar.g}</div>}
                        </Button>
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => ButtonCreate()} >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faCalendarCirclePlus} />{width > 768 && <div className="cs-dashboard-calendar-menu-text">{locale.dashboardCalendar.e}</div>}
                        </Button>
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => Navigation({card: 'close'})} >
                            <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className={StyleSectionMenu()} >
                <div className="mbsc-col" >
                    <div className="mbsc-row cs-dashboard-calendar-col-start">
                        {width > 375 &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => ButtonDateMinus()} >
                                <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faChevronLeft} />
                            </Button>
                        }
                        {width > 576 &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => ButtonDateToday()} >
                                {width > 768 ? locale.dashboardCalendar.m : <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faCircleNotch} />}
                            </Button>
                        }
                        {width > 375 &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-color" onClick={() => ButtonDatePlus()} >
                                <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faChevronRight} />
                            </Button>
                        }
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row cs-dashboard-calendar-col-end">
                        <Button color="primary" variant="flat" className="cs-dashboard-calendar-picker cs-dashboard-calendar-color mbsc-bold mbsc-txt-muted" >
                            <Datepicker
                                controls={['date']}
                                touchUi={width <= 576}
                                value={date}
                                inputComponent="input"
                                dateFormat={PickerFormat()}
                                dateWheels={PickerWheels()}
                                buttons={['set', 'cancel']}
                                onChange={ev => PickerUpdate(ev.value)}
                                setText={locale.dashboardCalendar.f}
                                cancelText={locale.dashboardCalendar.q}
                            />
                        </Button>
                        <Button id="dashboardCalendarRange" color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-calendar-color" onClick={() => Dialog({view: 'dashboardCalendarRange'})} >
                            {width > 768 ? ButtonRange() : <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faCalendarRange} />}
                        </Button>
                        <Button id="dashboardCalendarDisplay" color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-calendar-color" onClick={() => Dialog({view: 'dashboardCalendarDisplay'})} >
                            {width > 768 ? ButtonDisplay() : <FontAwesomeIcon className="cs-dashboard-calendar-icon" icon={faCalendarLinesPen} />}
                        </Button>
                    </div>
                </div>
            </div>
            {!refresh &&
                <div className="mbsc-row cs-dashboard-calendar-main">
                    <div className="mbsc-col cs-dashboard-calendar-col">
                        <Eventcalendar
                            view={CalendarView()}
                            eventOverlap={true}
                            dragToCreate={range !== 'month'}
                            dragToMove={range !== 'month'}
                            dragToResize={range !== 'month'}
                            clickToCreate={range === 'month' ? false : 'double'}
                            showControls={false}
                            renderScheduleEventContent={event => EventContent(event)}
                            renderLabel={event => EventContent(event)}
                            renderEventContent={event => EventContent(event)}
                            selectedDate={date}
                            data={calendar}
                            onEventCreated={(args, inst) => CalendarCreateInline(args, inst)}
                            onEventDoubleClick={args => display === 'scheduler' ? CalendarOpen(args) : false}
                            onEventClick={args => display === 'agenda' ? CalendarOpen(args) : false}
                            onSelectedDateChange={args => setDate(args.date)}
                            onEventDelete={args => display === 'scheduler' ? CalendarDelete(args) : false}
                            onEventUpdate={args => CalendarUpdate(args)}
                            onPageLoading={args => CalendarLoading(args)}
                        />
                    </div>
                </div>
            }
            {dialog.view === 'dashboardCalendarEvent' &&
                <DashboardCalendarEvent
                    Dialog={Dialog}
                    dialog={dialog}
                    visit={visit}
                    locale={locale}
                    setRefresh={setRefresh}
                    width={width}
                    setToast={setToast}
                    startTime={startTime}
                    endTime={endTime}
                    date={date}
                    anchor={anchor}
                />
            }
            {dialog.view === 'dashboardCalendarNew' &&
                <DashboardCalendarNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    visit={visit}
                    setRefresh={setRefresh}
                    width={width}
                    setToast={setToast}
                    anchor={anchor}
                    instance={instance}
                    startTime={startTime}
                    endTime={endTime}
                    date={date}
                />
            }
            {dialog.view === 'dashboardCalendarRange' &&
                <DashboardCalendarRange
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    range={range}
                    setRange={setRange}
                    ui={ui}
                    setUi={setUi}
                />
            }
            {dialog.view === 'dashboardCalendarDisplay' &&
                <DashboardCalendarDisplay
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    display={display}
                    setDisplay={setDisplay}
                    ui={ui}
                    setUi={setUi}
                />
            }
            {dialog.view === 'dashboardCalendarHours' &&
                <DashboardCalendarHours
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    width={width}
                    ui={ui}
                    setUi={setUi}
                    startTime={startTime}
                    endTime={endTime}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                />
            }
        </>
    );
}

export default DashboardCalendar;
