import React, {useContext, useState} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceImage.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceImage({locale, workspaceItem, setLoading}) {
    const {wsCall} = useContext(NetworkContext);
    const [menu, setMenu] = useState('original');
    const [image, setImage] = useState("data:" + workspaceItem.data.mime + ";base64," + workspaceItem.file);
    const [update, setUpdate] = useState(workspaceItem.update ? ("data:" + workspaceItem.data.update.mime + ";base64," + workspaceItem.data.update.file) : null);

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const ButtonDownload = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = workspaceItem.data.name;
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(image);
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    return (
        <>
            <div className="mbsc-row cs-dashboard-workspace-image-header-secondary" >
                <div className="mbsc-col" />
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row cs-dashboard-workspace-image-col-end">
                        {workspaceItem.update &&
                            <>
                                <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-image-color" onClick={() => ButtonUpdate('accept')} >
                                    {locale.dashboardWorkspaceImage.c}
                                </Button>
                                <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-image-color" onClick={() => ButtonUpdate('reject')} >
                                    {locale.dashboardWorkspaceImage.d}
                                </Button>
                            </>
                        }
                        {!workspaceItem.update &&
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-image-color" onClick={() => ButtonDownload()} >
                                {locale.dashboardWorkspaceImage.e}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-image-section">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-image-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceImage.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceImage.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {(!workspaceItem.update || (workspaceItem.update && menu === 'original')) &&
                <div className="mbsc-row cs-dashboard-workspace-image-section">
                    <div className="mbsc-col cs-dashboard-workspace-image-editor">
                        <div style={{backgroundImage: `url(${image})`}} className="cs-dashboard-workspace-image-canvas"  />
                    </div>
                </div>
            }
            {workspaceItem.update && menu === 'update' &&
                <div className="mbsc-row cs-dashboard-workspace-image-section">
                    <div className="mbsc-col cs-dashboard-workspace-image-editor">
                        <div style={{backgroundImage: `url(${update})`}} className="cs-dashboard-workspace-image-canvas"  />
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardWorkspaceImage;
