import React, {useState , useEffect, useRef, useCallback} from 'react';
import {Page} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import './styles/app.css';
import Login from './Login';
import Header from './Header';
import Dashboard from './Dashboard';
import Empty from './utils/Empty';
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
import {NetworkProvider} from './utils/NetworkProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

const RefToken = () => {
    let data = window.localStorage.token;
    if (!data) {
        data = '';
    }
    return data;
}

const RefAccount = () => {
    const itemDomain = window.location.hostname;
    const itemParts = itemDomain.split('.');
    const itemName = itemParts[itemParts.length - 2];

    let item = '';

    if (window.localStorage.account) {
        item = window.localStorage.account;
    } else if (itemParts.length > 2) {
        item = itemName + '-' + itemName;
    }

    return item;
}

const RefSession = () => {
    const itemMinutes = dayjs().utcOffset();
    const itemHours = (itemMinutes >= 0 ? '+' : '-') + String(Math.floor(Math.abs(itemMinutes) / 60)).padStart(2, '0') + ':' + String(Math.abs(itemMinutes) % 60).padStart(2, '0');

    return {
        navigator: {
            agent: navigator.userAgent,
            language: navigator.language,
            referrer: document.referrer
        },
        id: Math.floor(Math.random() * 1000000000000),
        version: "1.0",
        app: "eyJhbGciOiJIUzI1NiJ9.Q3ViaWNzdG9uZUFJ.Y97ljm0TB6p8xCnwLmwb3SuiZOp4qlJIIqGTcZnZB9s",
        timezone: dayjs.tz.guess(),
        offset: itemHours,
        width: 0,
        height: 0
    };
}

function App() {
    const token = useRef(RefToken());
    const session = useRef(RefSession());
    const wss = useRef('');
    const account = useRef(RefAccount());
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [configuration, setConfiguration] = useState({});
    const [locale, setLocale] = useState(English);
    const [shortener, setShortener] = useState('');
    const [reset, setReset] = useState('');
    const [handoff, setHandoff] = useState({});
    const [navigation, setNavigation] = useState({});
    const [dialog, setDialog] = useState({});

    const Width = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setHeight(height);
        session.current.height = height;
        setWidth(width);
        session.current.width = width;
    }

    const Locale = () => {
        if (window.localStorage.locale && window.localStorage.locale === 'en') {
            setLocale(English);
        } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
            setLocale(Spanish);
        } else {
            window.localStorage.locale = 'en';
            setLocale(English);
        }
    };

    const Navigation = useCallback(data => {
        const current = {...navigation};

        let view = data.view;
        let tab = data.tab;
        let card = data.card;

        if (tab && !view) {
            view = current.view;
        } else if (card === 'close') {
            view = current.view;
            tab = current.tab;
            card = '';
        } else if (card) {
            view = current.view;
            tab = current.tab;
        }
        const object = {
            view: view,
            tab: tab,
            card: card
        }
        setNavigation(object);
    }, [navigation]);

    const Dialog = useCallback(data => {
        let update = data;
        let current = dialog;

        let view = '';
        let tab = '';

        if (update.view && update.view === 'close') {
            view = '';
            tab = '';
        } else if (update.view || update.tab) {
            view = current.view;

            if (update.view) {
                view = update.view;
            } else if (current.view) {
                view = current.view;
            }
            if (update.tab) {
                tab = update.tab;
            } else if (current.tab) {
                tab = current.tab;
            }
        }
        let object = {
            view: view,
            tab: tab
        }
        setDialog(object);
    }, [dialog]);

    useEffect(() => {
        const location = window.location.href;
        const url = new URL(location);

        const itemShortener = url.searchParams.get('l');
        if (itemShortener) {
            setShortener(itemShortener);
        }

        const itemHandoff = url.searchParams.get('handoff');
        const itemAccount = url.searchParams.get('account');
        if (itemHandoff && itemAccount) {
            setHandoff({account: itemAccount, token: itemHandoff});
        }

        const itemReset = url.searchParams.get('reset');
        if (itemReset) {
            setReset(itemReset);
        }

        Locale();
        setNavigation({view: 'login'});

        window.history.replaceState(null, "CubicstoneAI", "/");
        document.title = 'CubicstoneAI';

        Width();
        window.addEventListener('resize', Width);
        return () => window.removeEventListener('resize', Width);
    }, []);

    return (
        <NetworkProvider locale={locale} session={session.current} token={token.current} wss={wss.current}>
            <Page theme="ios"  themeVariant="light">
                {navigation.view === 'login' &&
                    <Header
                        locale={locale}
                        dialog={dialog}
                        Dialog={Dialog}
                        configuration={configuration}
                        setLocale={setLocale}
                        setConfiguration={setConfiguration}
                        account={account}
                        wss={wss}
                        setDialog={setDialog}
                        setNavigation={setNavigation}
                        handoff={handoff}
                        setHandoff={setHandoff}
                        token={token}
                        width={width}
                    />
                }
                {navigation.view === 'login' &&
                    <Login
                        wss={wss}
                        token={token}
                        locale={locale}
                        dialog={dialog}
                        Dialog={Dialog}
                        shortener={shortener}
                        reset={reset}
                        setReset={setReset}
                        account={account.current}
                        configuration={configuration}
                        Navigation={Navigation}
                        width={width}
                    />
                }
                {!Empty(configuration) && navigation.view === 'dashboard' &&
                    <Dashboard
                        width={width}
                        locale={locale}
                        configuration={configuration}
                        dialog={dialog}
                        Dialog={Dialog}
                        Navigation={Navigation}
                        navigation={navigation}
                        setConfiguration={setConfiguration}
                        setLocale={setLocale}
                        token={token}
                    />
                }
            </Page>
        </NetworkProvider>
    );
}

export default App;
