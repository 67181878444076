import React, {useCallback, useContext} from 'react';
import {Popup, Input, Datepicker, Checkbox, Textarea, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardWorkspaceEvent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceEvent({Dialog, dialog, workspaceItem, locale, width, setWorkspaceItem, setLoading}) {
    const {wsCall} = useContext(NetworkContext);

    const DialogClose = useCallback(() => {
        setWorkspaceItem({});
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item-calendar',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const ButtonClose = () => {
        DialogClose();
    }

    return (
        <Popup className="cs-dashboard-workspace-event-popup" width={600} display="center" scrollLock={true} showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceEvent'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-workspace-event-header-col">
                <div className="mbsc-row cs-dashboard-workspace-event-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-event-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-event-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-event-header-col"} >
                        <Button className="cs-dashboard-workspace-event-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {workspaceItem.data.title}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-event-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-event-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-event-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-dashboard-workspace-event-grid">
                <div className="mbsc-row cs-dashboard-workspace-event-row">
                    <div className="mbsc-col cs-dashboard-workspace-event-grid">
                        <Datepicker
                            disabled
                            controls={['calendar', 'timegrid']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.dashboardWorkspaceEvent.c}
                            labelStyle="floating"
                            value={workspaceItem.data.start}
                            selectMultiple={false}
                            stepMinute={15}
                            buttons={['set', 'cancel']}
                            setText={locale.dashboardWorkspaceEvent.i}
                            cancelText={locale.dashboardWorkspaceEvent.q}
                        />
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-event-grid">
                        <Datepicker
                            disabled
                            controls={['calendar', 'timegrid']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.dashboardWorkspaceEvent.d}
                            labelStyle="floating"
                            value={workspaceItem.data.end}
                            selectMultiple={false}
                            stepMinute={15}
                            defaultSelection={workspaceItem.data.start}
                            buttons={['set', 'cancel']}
                            setText={locale.dashboardWorkspaceEvent.i}
                            cancelText={locale.dashboardWorkspaceEvent.q}
                        />
                    </div>
                </div>
            </div>
            <Input disabled inputStyle="underline" label={locale.dashboardWorkspaceEvent.b} labelStyle="floating" type="text" name="location" value={workspaceItem.data.location} />
            <mobiscroll.Color disabled theme="ios" themeVariant="light" touchUi={false} value={workspaceItem.data.color} >
                <mobiscroll.Input theme="ios" themeVariant="light" inputStyle="underline" >
                    {locale.dashboardWorkspaceEvent.e}
                </mobiscroll.Input>
            </mobiscroll.Color>
            <div className="cs-dashboard-workspace-event-textarea">
                <Textarea disabled inputStyle="underline" labelStyle="floating" label={locale.dashboardWorkspaceEvent.f} value={workspaceItem.data.notes} />
            </div>
            {workspaceItem.data.allDay &&
                <Checkbox
                    color="info"
                    label={locale.dashboardWorkspaceEvent.g}
                    checked={workspaceItem.data.allDay}
                    disabled
                    className="cs-dashboard-workspace-event-input"
                />
            }
            {workspaceItem.data.recurring &&
                <Input disabled inputStyle="underline" label={locale.dashboardWorkspaceEvent.h} labelStyle="floating" type="text" name="recurring" value={workspaceItem.data.recurring} />
            }
            <div className="mbsc-button-group-justified cs-dashboard-workspace-event-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-event-nav-button" onClick={() => ButtonUpdate('accept')} >
                    {locale.dashboardWorkspaceEvent.l}
                </Button>
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-event-nav-button" onClick={() => ButtonUpdate('reject')} >
                    {locale.dashboardWorkspaceEvent.k}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardWorkspaceEvent;
