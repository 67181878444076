import React, {useCallback, useState, useContext} from 'react';
import {Popup, Input, Checkbox, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderUser.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderUser({locale, dialog, Dialog, user, Logout, setUser, width}) {
    const {wsCall} = useContext(NetworkContext);
    const [notifications, setNotifications] = useState(user.notifications);
    const [mobile, setMobile] = useState(user.type === 'mobile' ? user.id : user.mobile);
    const [email, setEmail] = useState(user.type === 'email' ? user.id : user.email);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {

        const item = {...user};
        item.notifications = notifications;
        setUser(item);

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {}
        }
        if (notifications !== user.notifications) {
            message.data.notifications = notifications;
        }
        if (email !== user.email) {
            message.data.email = email;
        }
        if (mobile !== user.mobile) {
            message.data.mobile = mobile;
        }
        wsCall(message);
    }

    const ButtonClose = () => {
        if (notifications !== user.notifications ||
            email !== user.email ||
            mobile !== user.mobile) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    return (
        <Popup className="cs-dashboard-header-user-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderUser'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-user-header-col">
                <div className="mbsc-row cs-dashboard-header-user-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-user-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-user-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-user-header-col"} >
                        <Button className="cs-dashboard-header-user-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderUser.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-user-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-user-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-user-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-dashboard-header-user-header-col">
                <div className="mbsc-row cs-dashboard-header-user-header-row mbsc-justify-content-center">
                    <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className="cs-dashboard-header-user-avatar" />
                </div>
                <div className="mbsc-row cs-dashboard-header-user-header-row mbsc-padding mbsc-justify-content-center cs-dashboard-header-user-link">
                    <a href="https://en.gravatar.com" target="_blank">
                        {locale.dashboardHeaderUser.h}
                    </a>
                </div>
            </div>
            <Input disabled={user.type === 'mobile'} inputStyle="underline" label={locale.dashboardHeaderUser.f} labelStyle="floating" type="tel" name="mobile" value={mobile} onChange={ev => setMobile(ev.target.value)}/>
            <Input disabled={user.type === 'email'} placeholder={user.email ? locale.dashboardHeaderUser.m : locale.dashboardHeaderUser.d} inputStyle="underline" label={user.email ? locale.dashboardHeaderUser.d : locale.dashboardHeaderUser.m} labelStyle="floating" type="text" name="email" value={email} onChange={ev => setEmail(ev.target.value)}/>
            <Checkbox
                color="info"
                label={locale.dashboardHeaderUser.i}
                checked={notifications}
                onChange={(ev) => setNotifications(ev.target.checked)}
            />
            <Button variant="flat" color="secondary" className="cs-dashboard-header-user-info-button" >
                {locale.dashboardHeaderUser.j} {user.sessions.length} {locale.dashboardHeaderUser.k}
            </Button>
            <div className="mbsc-button-group-justified cs-dashboard-header-user-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-user-nav-button" onClick={() => Dialog({view: 'dashboardHeaderReset'})} >
                    {locale.dashboardHeaderUser.g}
                </Button>
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-user-nav-button" onClick={() => Logout('all')} >
                    {locale.dashboardHeaderUser.e}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardHeaderUser;
